import React from 'react';
import styled from "styled-components";
import IMAGE_XO_SO from '../Image/qcXsmb.jpg'
import {MdClose} from "react-icons/md";

const ModalCustom = styled.div`
  z-index: 2;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
`

const ContentCustom = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`

const RowCustom = styled.div`
  position: absolute;
  width: 320px;
  background-color: white;
  color: black;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  img {
    width: 320px;
    height: auto;
    border: 1px solid red;
  }

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: orange;
  }

  .content {
    text-align: center;
    font-style: italic;
    font-size: 20px;
    font-weight: bold;
    color: orange;
  }


`
const PopupQcXoSo = ({onClose}) => {
    return (
        <ModalCustom>
            <ContentCustom>
                <RowCustom>
                    <div style={{position: "relative"}}>
                        <MdClose onClick={onClose} size={24} style={{
                            position: "absolute",
                            right: "8px",
                            top: "8px",
                            color: "red",
                            cursor: "pointer"
                        }}/>
                        <div className={"title"}>VUI GIÁNG SINH</div>
                        <div className={"title"}>MỪNG NĂM MỚI</div>
                        <img src={IMAGE_XO_SO} alt=""/>
                    </div>
                </RowCustom>
            </ContentCustom>

        </ModalCustom>
    );
};

export default PopupQcXoSo;
